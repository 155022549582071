import { Box, styled, Typography } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useAllCarts,
    useApplication,
    useApplicationAppRightsAssignments,
} from 'hooks'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Filters } from 'components'
import AssigneePerson from 'components/AssigneePerson/AssigneePerson'
import useSubcomponents from 'useSubcomponents'
import { useHistory } from 'react-router'
import { ApplicationTypes } from 'utils'
import { PageContextProvider } from 'pageContext'
import ApplicationRoleDefinitionDrawer from '../ApplicationRoleDefinitionAssignments/ApplicationRoleDefinitionDetails'
import DeleteApplicationRoleRightAssignment from '../ApplicationRoleDefinitionAssignments/DeleteRoleRightAssignment'
import { useQuery } from 'packages/core'
import FieldTypeJsonPreviewButton from 'components/FieldTypeJsonPreview'
import ButtonWithDropdown from 'components/ButtonWithDropdown'
import { AssignRightsToPerson } from 'components/AssignRightsToResources/AssignRightsToPerson'
import { AssignRightsToGroup } from 'components/AssignRightsToResources/AssignRightsToGroup'
import { AssignRightsToManagementRole } from 'components/AssignRightsToResources/AssignToManagementRole'
import { AssignRightsToBusinessRoleLocation } from 'components/AssignRightsToResources/AssignToBusinessRoleLocation'
import { AssignRightsToSetGroup } from 'components/AssignRightsToResources/AssignRightsToSetGroup'
import moment from 'moment'
const TableOverView = styled(Box)({
    padding: '16px 0px',
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',

        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const ButtonsBox = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '10px',
    gap: '1rem',
})

const EditButton = styled(Button)(({ theme }) => ({
    borderRadius: '4px',
    backgroundColor: theme?.palette?.primary?.main,
    color: '#ffffff',
    fontSize: '14px',
    height: '100%',
    '&:hover': {
        backgroundColor: theme?.palette?.primary?.main,
    },
}))
const RightAssignContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'space-between',
    justifyContent: 'space-between',
})
const FilerSearchContainer = styled(Box)({
    width: '240px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
})

const ApplicationAppRightsAssignments = ({
    resourceSystemModuleID,
    resourceId,
    applicationType,
}) => {
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()
    const { data: carts } = useAllCarts()
    const appRightId = query.get('rightId')
    const applicationId = query.get('applicationId')
    const { data: application } = useApplication(applicationId)
    const { list, loading, loadingMore, pagination } =
        useApplicationAppRightsAssignments(
            `/api/LocalRights/appRoles/membersByApplicationID`,
            resourceSystemModuleID,
        )
    const {
        canAddApplicationAppRightAssignments,
        getAccessBasedAttributes,
        hasAccessToControl,
    } = useSubcomponents()
    const attributes = [
        {
            name: 'assignee',
            sortable: true,
            label: 'Assignee',
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <AssigneePerson
                            assigneeName={data.assignee}
                            imageUrl={data.personImageThumbUrl}
                            assigneeType={data.value}
                        />
                    )
                },
            },
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRights-Assignee',
            // },
        },
        {
            name: 'appRightFriendlyName',
            sortable: true,
            label: 'AppRight',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRights-AppRight',
            },
        },
        {
            name: 'appRightName',
            sortable: true,
            label: 'TechnicalName',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRights-TechnicalName',
            },
        },
        {
            name: 'rightType',
            sortable: true,
            label: 'RightType',

            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRights-RightType',
            },
        },
        {
            name: 'assignedTo',
            sortable: true,
            label: 'AssignedTo',

            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRights-AssignedTo',
            },
        },
        {
            name: 'application',
            sortable: true,
            label: 'Application',

            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRights-Application',
            },
        },

        {
            name: 'resourceSystem',
            sortable: true,
            label: 'ResourceSystem',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRights-ResourceSystem',
            },
        },
        {
            name: 'startDate',
            sortable: true,
            label: 'StartDate',
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRights-StartDate',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Typography sx={{ fontSize: '1.3rem' }}>
                            {data.startDate
                                ? moment
                                      .utc(data.startDate)
                                      .local()
                                      .format('L LT')
                                : '-'}
                        </Typography>
                    )
                },
            },
        },
        {
            name: 'endDate',
            sortable: true,
            label: 'EndDate',
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRights-EndDate',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Typography sx={{ fontSize: '1.3rem' }}>
                            {data.endDate
                                ? moment
                                      .utc(data.endDate)
                                      .local()
                                      .format('L LT')
                                : '-'}
                        </Typography>
                    )
                },
            },
        },
        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRights-Action',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <ButtonsBox>
                            <FieldTypeJsonPreviewButton data={data} />
                            <Box>
                                {(applicationType ===
                                    ApplicationTypes.PbacHasAppResourcesWithFieldType ||
                                    applicationType ===
                                        ApplicationTypes.PBACHasNoAppResourceWithFieldType ||
                                    applicationType ===
                                        ApplicationTypes.AzureAppWithPbac) && (
                                    <EditButton
                                        onClick={() => {
                                            query.set('rightId', data?.id)
                                            history.push(
                                                `${
                                                    history.location.pathname
                                                }?${query.toString()}`,
                                            )
                                        }}
                                    >
                                        <Box style={{ display: 'flex' }}>
                                            {t('Edit')}
                                        </Box>
                                    </EditButton>
                                )}
                            </Box>
                            <DeleteApplicationRoleRightAssignment
                                item={data}
                                carts={carts}
                                application={application}
                                keyForAssignee={'appRightFriendlyName'}
                                keyForRoleRightId={'localRightId'}
                            />
                        </ButtonsBox>
                    )
                },
            },
        },
    ]
    const [assignmentModalValue, setAssignmentModalValue] = useState('')
    const assignRightsDropdownOptions = [
        {
            label: 'AssignToPerson',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRightsAssignment-AssignToPerson',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToPerson')
            },
        },
        {
            label: 'AssignToManagementRole',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRightsAssignment-AssignToManagementRole',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToManagementRole')
            },
        },
        {
            label: 'AssignToGroup',
            display: false,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRightsAssignment-AssignToGroup',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToGroup')
            },
        },
        {
            label: 'AssignToBusinessRoleLocation',
            display: false,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRightsAssignment-AssignToBusinessRoleLocation',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToBusinessRoleLocation')
            },
        },
        {
            label: 'AssignToSetGroup',
            display: false,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRightsAssignment-AssignToSetGroup',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToSetGroup')
            },
        },
    ]
    const getOptionsToRender = () => {
        return assignRightsDropdownOptions.filter(
            (d) => d.display && hasAccessToControl(d?.requireAccess?.control),
        )
    }
    return (
        <Fragment>
            <Box>
                <RightAssignContainer>
                    {canAddApplicationAppRightAssignments && (
                        <Box
                            data-protectedsubcomponent={
                                canAddApplicationAppRightAssignments
                            }
                        >
                            {getOptionsToRender().length > 0 && (
                                <ButtonWithDropdown
                                    label={'AssignAppRight'}
                                    options={getOptionsToRender()}
                                />
                            )}
                        </Box>
                    )}

                    <FilerSearchContainer>
                        <SearchMenuBarFilter>
                            <Filters.TextSearch outlined />
                        </SearchMenuBarFilter>
                    </FilerSearchContainer>
                </RightAssignContainer>
                <TableOverView>
                    <EidTableGrid
                        rowSelection={false}
                        selectAllAction={null}
                        noDataMessage={t('NoDataMessage')}
                        pagination={pagination}
                        isLoading={loading}
                        isLoadingMore={loadingMore}
                        attributes={getAccessBasedAttributes(attributes)}
                        list={list}
                    />
                </TableOverView>
            </Box>
            {appRightId && list?.length && (
                <PageContextProvider key="RequestViewAppRights">
                    <ApplicationRoleDefinitionDrawer
                        assigneePerson={list.find((x) => x.id === appRightId)}
                        keyForAssignee={'appRightFriendlyName'}
                        keyForRoleRightId={'localRightId'}
                        isRole={false}
                        onClose={() => {
                            query.delete('rightId')
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </PageContextProvider>
            )}
            {assignmentModalValue === 'AssignToPerson' && (
                <AssignRightsToPerson
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={applicationId}
                    resourceSystemModuleID={resourceSystemModuleID}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                />
            )}
            {assignmentModalValue === 'AssignToGroup' && (
                <AssignRightsToGroup
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={applicationId}
                    resourceSystemModuleID={resourceSystemModuleID}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                />
            )}
            {assignmentModalValue === 'AssignToManagementRole' && (
                <AssignRightsToManagementRole
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={applicationId}
                    resourceSystemModuleID={resourceSystemModuleID}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                />
            )}
            {assignmentModalValue === 'AssignToBusinessRoleLocation' && (
                <AssignRightsToBusinessRoleLocation
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={applicationId}
                    resourceSystemModuleID={resourceSystemModuleID}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                />
            )}
            {assignmentModalValue === 'AssignToSetGroup' && (
                <AssignRightsToSetGroup
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={applicationId}
                    resourceSystemModuleID={resourceSystemModuleID}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                />
            )}
        </Fragment>
    )
}

export default ApplicationAppRightsAssignments
